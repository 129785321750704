import React from 'react';

const RecentAnalyses = ({ recentProperties }) => {
  const handleViewClick = (zillowUrl) => {
    if (zillowUrl) {
      window.open(zillowUrl, '_blank');
    }
  };

  return (
    <div className="mt-8 w-full ">
      <h3 className="text-2xl font-bold mb-2">Recent Analyses</h3>
      <ul className="space-y-2 shadow">
        {recentProperties.map((property, index) => (
          <li
            key={property.zpid || index}
            className="flex flex-row justify-between items-center bg-white p-3 rounded shadow"
          >
            <div className="property-info flex items-center">
              <svg viewBox="0 0 24 24" className="w-6 h-6 mr-2 text-emerald-600">
                <path
                  fill="currentColor"
                  d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"
                />
              </svg>
              {/* Full Address for medium and larger screens */}
              <span className="truncate hidden sm:block">{property.address}</span>
              {/* Street Address for small screens */}
              <span className="truncate sm:hidden">{property.streetAddress}, {property.city}</span>
            </div>
            <button
              onClick={() => handleViewClick(property.zillowUrl)}
              className="bg-emerald-600 text-white px-3 py-1 rounded hover:bg-emerald-500 transition duration-300"
            >
              View
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentAnalyses;