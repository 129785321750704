import React, { useState, useEffect } from 'react';
import { getComparisonRows } from './PropertyComparisonService';
import GreenFlagsPopup from './GreenFlagsPopup';
import RedFlagsPopup from './RedFlagsPopup';
import PropertyComparisonService from './PropertyComparisonService';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

function PropertyComparisonView({ properties }) {
  const [propertiesWithPricePerSqFt, setPropertiesWithPricePerSqFt] = useState(
    properties.map(property => ({
      ...property,
      pricePerSqFt: property.squareFootage && property.squareFootage !== 0
        ? (property.price / property.squareFootage).toFixed(2)
        : 'N/A',
      greenFlags: property.greenFlags || [],
      redFlags: property.redFlags || [], // Initialize redFlags
    }))
  );

  // State for Green Flags Popup
  const [isGreenPopupOpen, setIsGreenPopupOpen] = useState(false);
  const [currentGreenProperty, setCurrentGreenProperty] = useState(null);
  const [newGreenFlags, setNewGreenFlags] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  // State for Red Flags Popup
  const [isRedPopupOpen, setIsRedPopupOpen] = useState(false);
  const [currentRedProperty, setCurrentRedProperty] = useState(null);
  const [newRedFlags, setNewRedFlags] = useState('');
  
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (!currentUser) {
        setLoading(false);
        setError('User not authenticated. Please sign in to view search history.');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!loading) {
      setIsVisible(true);
    }
  }, [loading]);

  
  // Handlers for Green Flags
  const handleAddGreenFlags = (property) => {
    setCurrentGreenProperty(property);
    setNewGreenFlags(property.greenFlags.join(', '));
    setIsGreenPopupOpen(true);
  };

  const handleSaveGreenFlags = (newFlags) => {
    if (currentGreenProperty) {
      setPropertiesWithPricePerSqFt(prevProperties =>
        prevProperties.map(prop => {
          if (prop.zpid === currentGreenProperty.zpid) {
            return { ...prop, greenFlags: newFlags };
          }
          return prop;
        })
      );

      if (user) {
        const searchHistoryRef = collection(db, 'users', user.uid, 'searchHistory');
        const q = query(searchHistoryRef, orderBy('timestamp', 'desc'));
        
        // Process querySnapshot as needed
      }
      setIsGreenPopupOpen(false);
      setCurrentGreenProperty(null);
      setNewGreenFlags('');
    }
  };

  const handleCloseGreenPopup = () => {
    setIsGreenPopupOpen(false);
    setCurrentGreenProperty(null);
    setNewGreenFlags('');
  };

  // Handlers for Red Flags
  const handleAddRedFlags = (property) => {
    setCurrentRedProperty(property);
    setNewRedFlags(property.redFlags.join(', '));
    setIsRedPopupOpen(true);
  };

  const handleSaveRedFlags = (newFlags) => {
    if (currentRedProperty) {
      setPropertiesWithPricePerSqFt(prevProperties =>
        prevProperties.map(prop => {
          if (prop.zpid === currentRedProperty.zpid) {
            return { ...prop, redFlags: newFlags };
          }
          return prop;
        })
      );
      setIsRedPopupOpen(false);
      setCurrentRedProperty(null);
      setNewRedFlags('');
    }
  };

  const handleCloseRedPopup = () => {
    setIsRedPopupOpen(false);
    setCurrentRedProperty(null);
    setNewRedFlags('');
  };

  return (
    <div className="max-w-6xl mx-auto">
      <h3 className="text-2xl font-bold mb-4">Property Comparison</h3>
      <p className="text-sm italic text-gray-600 mb-4">
        Compare multiple properties side by side to make informed decisions about potential purchases.
      </p>
     
          <PropertyComparisonService 
            properties={propertiesWithPricePerSqFt}
            onAddGreenFlags={handleAddGreenFlags}
            onAddRedFlags={handleAddRedFlags}
          />
          

      {/* Green Flags Popup */}
      {isGreenPopupOpen && (
        <GreenFlagsPopup
          onClose={handleCloseGreenPopup}
          onSave={handleSaveGreenFlags}
          greenFlags={newGreenFlags}
          setGreenFlags={setNewGreenFlags}
        />
      )}

      {/* Red Flags Popup */}
      {isRedPopupOpen && (
        <RedFlagsPopup
          onClose={handleCloseRedPopup}
          onSave={handleSaveRedFlags}
          redFlags={newRedFlags}
          setRedFlags={setNewRedFlags}
        />
      )}
    </div>
  );
}

export default PropertyComparisonView;
