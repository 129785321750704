import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { formatNumber, LabelValue, formatHomeType } from "../../utils/CustomComponents";
import { analyzeProperty, calculateMonthlyCosts, calculateSavings, calculateCashToClose } from "./PropertyAnalysisService";
import { FaInfoCircle } from "react-icons/fa";
import ClosingCostsPopup from "./ClosingCostsPopup";
import MonthlyCostsPopup from "./MonthlyCostsPopup";
import { fetchInterestRates } from "../../utils/MortgageApi";
import { getAuth } from 'firebase/auth';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';

function PropertyAnalysisView({ addProperty, analyzedProperty }) {
  const [zillowLink, setZillowLink] = useState("");
  const [propertyData, setPropertyData] = useState(analyzedProperty);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [linkClicked, setLinkClicked] = useState(false);
  const [showAssumptions, setShowAssumptions] = useState(false);
  const [showClosingCostsPopup, setShowClosingCostsPopup] = useState(false); // Existing state for closing costs popup
  const [showMonthlyCostsPopup, setShowMonthlyCostsPopup] = useState(false); // New state for monthly costs popup
  const [financeAgentFee, setFinanceAgentFee] = useState(false); // State to manage financing agent fee
  const [includeInternetTv, setIncludeInternetTv] = useState(true); // New state for Internet/TV toggle
  const propertyDetailsRef = useRef(null);
  const navigate = useNavigate();
  const [interestRates, setInterestRates] = useState({ rate15Year: 0, rate30Year: 0 });
  const [isRatesLoading, setIsRatesLoading] = useState(true);

  useEffect(() => {
    const hasClicked = localStorage.getItem("linkClicked");
    if (hasClicked === "true") {
      setLinkClicked(true);
    }
  }, []);

  useEffect(() => {
    const fetchRates = async () => {
      setIsRatesLoading(true);
      const rates = await fetchInterestRates();
      setInterestRates(rates);
      setIsRatesLoading(false);
    };
    fetchRates();
  }, []);

  useEffect(() => {
    if (analyzedProperty) {
      setPropertyData(analyzedProperty);
      setZillowLink(analyzedProperty.zillowLink || "");
      // Scroll to Savings section immediately
      if (propertyDetailsRef.current) {
        propertyDetailsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [analyzedProperty]);

  const openChatBot = () => {
    navigate("/chatbot", { 
      state: { 
        propertyData,
        from: window.location.pathname
      } 
    });
  };

  const analyzePropertyHandler = async () => {
    setIsLoading(true);
    setError(null);
    setIsRatesLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;

    try {
      const data = await analyzeProperty(zillowLink);
      console.log("Analyzed property data:", data);
      setPropertyData(data);
      addProperty(data);

      if (user) {
        try {
          // Use setDoc with merge option to update or create the document
          await setDoc(doc(db, 'users', user.uid, 'searchHistory', data.zpid.toString()), {
            address: data.address,
            image1: data.photos && data.photos.length > 0 ? data.photos[0] : null,
            timestamp: serverTimestamp(),
            insights: data.insights,
            price: data.price,
            pricePerSqFt: Math.round(data.price / data.squareFootage),
            zestimate: data.zestimate,
            homeType: data.homeType,
            squareFootage: data.squareFootage,
            bedrooms: data.bedrooms,
            bathrooms: data.bathrooms,
            yearBuilt: data.yearBuilt,
            listingDate: data.listingDate,
            zpid: data.zpid,
            zillowUrl: data.zillowUrl,
            redFlags: [],
            greenFlags: []
          }, { merge: true });
          console.log("Search history saved for property ID:", data.zpid);
        } catch (error) {
          console.error('Error saving search history:', error);
        }
      } else {
        console.log("User not authenticated, search history not saved");
      }

      // Set flag in localStorage when analyzing a property
      localStorage.setItem("hasAnalyzed", "true");
      setLinkClicked(true);

      const fetchedRates = await fetchInterestRates();
      setInterestRates(fetchedRates);

      // Scroll to Savings section immediately
      if (propertyDetailsRef.current) {
        propertyDetailsRef.current.scrollIntoView({ behavior: "smooth" });
      }
    } catch (err) {
      setError(err.message);
      console.error(err);
    } finally {
      setIsLoading(false);
      setIsRatesLoading(false);
    }
  };

  const monthlyCosts = propertyData
    ? calculateMonthlyCosts(propertyData, includeInternetTv, interestRates)
    : null;
  const savings = propertyData
    ? calculateSavings(propertyData, interestRates)
    : null;
  const cashToClose = propertyData
    ? calculateCashToClose(propertyData, financeAgentFee)
    : null;

  const toggleAssumptions = () => {
    setShowAssumptions(!showAssumptions);
  };

  // Toggle functions for Popups
  const toggleClosingCostsPopup = () => {
    setShowClosingCostsPopup(!showClosingCostsPopup);
  };

  const closeClosingCostsPopup = () => {
    setShowClosingCostsPopup(false);
  };

  const toggleMonthlyCostsPopup = () => {
    setShowMonthlyCostsPopup(!showMonthlyCostsPopup);
  };

  const closeMonthlyCostsPopup = () => {
    setShowMonthlyCostsPopup(false);
  };

  // Toggle functions for Finance Agent Fee and Internet/TV
  const handleFinanceAgentFeeToggle = () => {
    setFinanceAgentFee(!financeAgentFee);
  };

  const handleInternetTvToggle = () => {
    setIncludeInternetTv(!includeInternetTv);
  };

  return (
    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
      <h3
        className="text-2xl font-bold mb-4 text-gray-800"
        ref={propertyDetailsRef}
      >
        Property Analysis
      </h3>
      <p className="text-sm italic text-gray-600 mb-4">
        Analyze your property and calculate potential savings with Unrepped.
      </p>
      {/* Conditionally render the "No house in mind?" line */}
      {!linkClicked && (
        <p className="mb-4">
          Just playing around? Try sample link:{" "}
          <a
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              setZillowLink(
                "https://www.zillow.com/homedetails/2501-Quintara-St-San-Francisco-CA-94116/15117300_zpid/"
              );
              setLinkClicked(true);
              // Removed storing 'linkClicked' in localStorage
              // localStorage.setItem("linkClicked", "true");
            }}
            className="underline text-blue-600 hover:text-blue-800"
          >
            Sample Link
          </a>
        </p>
      )}
      <div className="mb-4">
        <input
          type="text"
          value={zillowLink}
          onChange={(e) => setZillowLink(e.target.value)}
          className="w-full max-w-md p-2 border border-gray-300 rounded"
          placeholder="Paste Zillow link here"
        />
        <div className="mt-2 space-x-2">
          <button
            type="button"
            onClick={analyzePropertyHandler}
            disabled={isLoading}
            className="bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-500 transition duration-300"
          >
            {isLoading ? "Analyzing..." : "Analyze"}
          </button>
          {propertyData && (
            <button
              type="button"
              onClick={openChatBot}
              className="bg-shiny-gradient border-2 border-emerald-600 text-white px-4 py-2 rounded animate-gradient-x hover:animate-gradient-x-fast hover:shadow-lg transition-all ease-in-out bg-[length:200%_200%] hover:bg-[length:150%_150%] hover:brightness-110"
            >
              Ask Unrepped AI
            </button>
          )}
        </div>
      </div>
      {error && <p className="text-red-600 mb-4">{error}</p>}

      {/* Property Photos */}
      {propertyData && (
        <div className="mb-4">
          <div className="flex flex-wrap gap-2">
            {propertyData.photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Property Photo ${index + 1}`}
                className="w-5/12 h-auto rounded-lg shadow-md"
              />
            ))}
          </div>
        </div>
      )}

      {/* Overall Buying Cost */}
      <div>
        {propertyData && (
          <>
            <h3 className="text-xl font-semibold mb-2 mt-6 text-gray-800">Overall Buying Cost</h3>
            <div className="flex flex-col">
              {/* Cash to Close */}
              <div className="flex items-center">
                <LabelValue label="Cash to Close" value={`$${formatNumber(cashToClose.total)}`} className="flex items-center" />
                <FaInfoCircle className="mb-1 ml-2 text-emerald-600 cursor-pointer hover:text-emerald-500" onClick={toggleClosingCostsPopup} title="Total cash to close" />
              </div>  

              {/* Total Monthly Costs */}
              <div className="flex items-center">
                <LabelValue label="Total Monthly Costs" value={`$${formatNumber(monthlyCosts.total)}`} className="flex items-center" />
                <FaInfoCircle className="mb-1 ml-2 text-emerald-600 cursor-pointer hover:text-emerald-500" onClick={toggleMonthlyCostsPopup} title="Total monthly costs" />
              </div>
            </div>

            {/* Popup Modals */}
            {showClosingCostsPopup && (
              <ClosingCostsPopup
                onClose={closeClosingCostsPopup}
                cashToClose={cashToClose}
                financeAgentFee={financeAgentFee}
                onToggleFinanceAgentFee={handleFinanceAgentFeeToggle}
              />
            )}
            {showMonthlyCostsPopup && (
              <MonthlyCostsPopup
                onClose={closeMonthlyCostsPopup}
                monthlyCosts={monthlyCosts}
                includeInternetTv={includeInternetTv}
                onToggleInternetTv={handleInternetTvToggle}
                interestRates={interestRates} // Pass the interestRates here
              />
            )}

            {/* Savings Using Unrepped */}
            <h3 className="text-xl font-semibold mb-2 mt-6 text-gray-800">Savings Using Unrepped</h3>
            {/* New Down Payment Savings Section with Inline Button */}

            <LabelValue label="3% Agent Fee" value={`$${formatNumber(parseFloat(savings.agentFee).toFixed(2))}`} />
            <LabelValue label="Your Monthly Savings" value={`$${formatNumber(parseFloat(savings.monthlySavings).toFixed(2))}`} />
            <LabelValue label="Your Total Savings over 30 years" value={`$${formatNumber(parseFloat(savings.totalSavings).toFixed(2))}`} />
            



            <h3 className="text-xl font-semibold mb-2 mt-6 text-gray-800">Property Details</h3>
            <LabelValue label="Insights" value={propertyData.insights ? propertyData.insights.join('✔️ ') + '✔️' : "Not given"} />
            <LabelValue label="Address" value={propertyData.address || "Not given"} />
            <LabelValue label="Price" value={propertyData.price ? `$${formatNumber(propertyData.price)}` : "Not given"} />
            <LabelValue label="Zestimate" value={propertyData.zestimate ? `$${formatNumber(propertyData.zestimate)}` : "Not given"} />
            <LabelValue label="Home Type" value={formatHomeType(propertyData.homeType) || "Not given"} />
            <LabelValue label="Square Footage" value={propertyData.squareFootage ? formatNumber(propertyData.squareFootage) : "Not given"} />
            <LabelValue label="Bedrooms" value={propertyData.bedrooms || "Not given"} />
            <LabelValue label="Bathrooms" value={propertyData.bathrooms || "Not given"} />
            <LabelValue label="Year Built" value={propertyData.yearBuilt || "Not given"} />
            <LabelValue label="Days on Zillow" value={propertyData.listingDate || "Not given"} />
            <LabelValue label="Zillow Property ID" value={propertyData.zpid || "Not given"} />
            <LabelValue label="Zillow URL" value={propertyData.zillowUrl || "Not given"} />

            {monthlyCosts && (
              <>
                <h3
                  className="text-xl font-semibold mb-2 mt-6 text-gray-800 cursor-pointer flex items-center"
                  onClick={toggleAssumptions}
                >
                  Mortgage Assumptions
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className={`ml-2 transition-transform duration-300 ${showAssumptions ? "transform rotate-180" : ""}`} viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </h3>
                {showAssumptions && (
                  <ul className="list-disc pl-5 mb-4 text-gray-700">
                    <li>Down Payment: 20%</li>
                    <li>Interest Rate: {interestRates.rate30Year}% (FRED) </li>
                    <li>Loan Term: 30 years fixed</li>
                    <li>Credit Score: Tier 1</li>
                  </ul>
                )}
              </>
            )}
          </>
        )}
      </div>
      {/*{isRatesLoading && <p>Loading current mortgage rates...</p>}*/}
    </div>
  );
}

export default PropertyAnalysisView;
