import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { formatNumber, formatHomeType } from '../../utils/CustomComponents';
import AccountManagement from './AccountManagement';
import { getPreferences, calculateCompatibilityScore, getGradeAndColor, getCompatibilityDetails } from './PreferencesService';

const SearchHistory = () => {
  const [searchHistory, setSearchHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [userPreferences, setUserPreferences] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchSearchHistory(currentUser.uid);
        fetchUserPreferences(currentUser.uid);
      } else {
        setLoading(false);
        setError('User not authenticated. Please sign in to view search history.');
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!loading) {
      setIsVisible(true);
    }
  }, [loading]);

  const fetchSearchHistory = async (userId) => {
    try {
      setLoading(true);
      const searchHistoryRef = collection(db, 'users', userId, 'searchHistory');
      const q = query(searchHistoryRef, orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const history = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched search history:', history); // Debugging line
      setSearchHistory(history);
    } catch (error) {
      console.error('Error fetching search history:', error);
      setError('Failed to load search history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserPreferences = async (userId) => {
    try {
      const preferences = await getPreferences(userId);
      setUserPreferences(preferences);
    } catch (error) {
      console.error('Error fetching user preferences:', error);
    }
  };

  const getScoreColor = (score) => {
    if (score <= 33) return 'bg-red-500';
    if (score <= 66) return 'bg-yellow-500';
    return 'bg-emerald-500';
  };

  const openPropertyDetails = (property) => {
    setSelectedProperty(property);
  };

  const closePropertyDetails = () => {
    setSelectedProperty(null);
  };

  if (error) {
    return <div className="text-center py-8 text-red-600">{error}</div>;
  }
  
  return (
    <div className={`max-w-6xl mx-auto py-8 transition-opacity duration-300 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
      <AccountManagement user={user} />
      <h2 className="text-2xl font-bold mb-4 pt-8">Searched Properties</h2>
      {user && <p className="mb-4">A list of recent properties you've searched for.</p>}
      
      {searchHistory.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {searchHistory.map((property) => (
            <div key={property.id} className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 relative">
              {userPreferences && (
                <div className={`absolute top-2 right-2 bg-emerald-600 text-white rounded-full w-10 h-10 flex items-center justify-center ${getScoreColor(calculateCompatibilityScore(userPreferences, property))}`}>
                  {(() => {
                    const score = calculateCompatibilityScore(userPreferences, property);
                    const { grade, color } = getGradeAndColor(score);
                    return (
                      <div className="group relative">
                        <div className={`w-10 h-10 rounded-full flex items-center justify-center font-bold text-lg ${color}`}>
                          {grade}
                        </div>
                        <div className="absolute right-0 w-48 p-2 mt-2 text-sm bg-white rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-20">
                          <p className="font-semibold mb-1 text-gray-700">Compatibility Levels:</p>
                          <pre className="whitespace-pre-wrap text-xs text-gray-700">
                            {getCompatibilityDetails(userPreferences, property)}
                          </pre>
                        </div>
                      </div>
                    );
                  })()}
                </div>
              )}
              <h3 className="font-semibold text-lg mb-2 mr-12">{property.address}</h3>
              {property.image1 ? (
                <img src={property.image1} alt={property.address} className="w-full h-40 object-cover rounded-md mb-2" />
              ) : (
                <div className="w-full h-40 bg-gray-200 flex items-center justify-center rounded-md mb-2">
                  <span className="text-gray-500">No image available</span>
                </div>
              )}
              <p className="text-sm text-gray-600 mb-1">Zillow ID: {property.id}</p>
              <p className="text-sm text-gray-600 mb-2">
                Analyzed: {property.timestamp && new Date(property.timestamp.toDate()).toLocaleString()}
              </p>
              <button 
                onClick={() => openPropertyDetails(property)}
                className="inline-block bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-500 transition duration-300"
              >
                View Analysis
              </button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-center text-gray-600">No search history available. Try analyzing some properties!</p>
      )}

      {selectedProperty && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4">{selectedProperty.address}</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <p><strong>Price:</strong> ${formatNumber(selectedProperty.price)}</p>
                <p><strong>Zestimate:</strong> ${formatNumber(selectedProperty.zestimate)}</p>
                <p><strong>Square Footage:</strong> {formatNumber(selectedProperty.squareFootage)}</p>
                <p><strong>Bedrooms:</strong> {selectedProperty.bedrooms}</p>
                <p><strong>Bathrooms:</strong> {selectedProperty.bathrooms}</p>
                <p><strong>Home Type:</strong> {formatHomeType(selectedProperty.homeType)}</p>
              </div>
              <div>
                <p><strong>Compatibility Score:</strong> {calculateCompatibilityScore(userPreferences, selectedProperty)}</p>
                <p><strong>Year Built:</strong> {selectedProperty.yearBuilt}</p>
                <p><strong>Days on Zillow:</strong> {selectedProperty.listingDate}</p>
                <p><strong>Zillow Property ID:</strong> {selectedProperty.zpid}</p>
                <p><strong>Zillow URL:</strong> <a href={selectedProperty.zillowUrl} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">View on Zillow</a></p>
              </div>
            </div>
            <div className="mt-4">
              <strong>Insights:</strong>
              <ul className="list-disc pl-5">
                {selectedProperty.insights && selectedProperty.insights.map((insight, index) => (
                  <li key={index}>{insight}</li>
                ))}
              </ul>
            </div>
            <button
              onClick={closePropertyDetails}
              className="mt-6 bg-red-600 text-white px-4 py-2 rounded hover:bg-red-500 transition duration-300"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchHistory;
