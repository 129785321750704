import React, { useState, useEffect, useRef} from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';

export default function Component() {
  const [currentStep, setCurrentStep] = useState(0);
  const [householdSize, setHouseholdSize] = useState('');
  const [ownsHome, setOwnsHome] = useState('');
  const [income, setIncome] = useState('');
  const [militaryStatus, setMilitaryStatus] = useState([]);
  const [isNativeAmerican, setIsNativeAmerican] = useState(false);
  const [professions, setProfessions] = useState([]);
  const [hasDisability, setHasDisability] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [assistancePrograms, setAssistancePrograms] = useState('23'); // Example: number of assistance programs found
  const location = useLocation();
  const navigate = useNavigate();
  const propertyData = location.state?.propertyData || null;
  const previousPath = location.state?.from || '/userapp'; // Default to /userapp if not specified
  const [showPrograms, setShowPrograms] = useState(false);
  const [showAdditionalPrograms, setShowAdditionalPrograms] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(true);
  const popupRef = useRef(null);

  // Close the modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowModal(false); // Close modal
      }
    };

    // Add event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupRef]);

  const programs = [
    { amount: "$500,000", name: "San Francisco Down Payment Assistance Loan Program (DALP) 200% AMI" },
    { amount: "$500,000", name: "San Francisco Dream Keeper Downpayment Assistance Loan Program (DK-DALP)" },
    { amount: "$500,000", name: "San Francisco Down Payment Assistance Loan Program (DALP) 120% AMI" },
    { amount: "$500,000", name: "San Francisco City Second Loan Program (CSLP)" },
    { amount: "Up to $127,868 (20% of Purchase Price)", name: "San Francisco BMR Down Payment Assistance Loan Program (BMR DALP) Waitlist" },
    { amount: "$55,000", name: "United Auburn Indian Community Down Payment Assistance Program" },
    { amount: "$50,000", name: "Federal Home Loan Bank (FHLB) of San Francisco – Middle-Income Homebuyers Program" },
    { amount: "Up to $36,411 (5% of Total Loan Amount)", name: "Golden State Finance Authority (GSFA) Golden Opportunities Program" },
    { amount: "$10,000", name: "Pathway to Homeownership Closing Cost Assistance Grant Program Northern California" },
    { amount: "$10,000", name: "Community Housing Development Corporation (CHDC) Black Wealth Initiative (BWI) DPA Program" },
    { amount: "20% of Purchase Price", name: "California Housing Finance Agency (CalHFA) – Dream For All Shared Appreciation Loan Temporarily Suspended" },
    { amount: "15% of Purchase Price", name: "Electronic Frontier Foundation Employer Assisted Housing Program" },
    { amount: "5.5% of Total Loan Amount", name: "Golden State Finance Authority (GSFA) Platinum Assist To Own – Conventional" },
    { amount: "5% of Purchase Price", name: "National Homebuyers Fund (NHF) – Data Mortgage, Inc. dba Essex Mortgage 5% Repayable Down Payment Assistance Program" },
    { amount: "5% of Total Loan Amount", name: "Golden State Finance Authority (GSFA) Platinum SELECT Down Payment Assistance Program – HFA Advantage" },
    { amount: "5% of Total Loan Amount", name: "Golden State Finance Authority (GSFA) Platinum SELECT Down Payment Assistance Program – FHA/VA/USDA" },
    { amount: "5% of Purchase Price", name: "CBC Mortgage Chenoa Fund – FHA Repayable DPA" },
    { amount: "5% of Purchase Price", name: "CBC Mortgage Chenoa Fund – FHA Forgivable DPA" },
    { amount: "3.5% of Purchase Price", name: "National Homebuyers Fund (NHF) – Data Mortgage, Inc. dba Essex Mortgage Deferred Down Payment Assistance Program" },
    { amount: "3.5% of Purchase Price", name: "National Homebuyers Fund (NHF) – Data Mortgage, Inc. dba Essex Mortgage 3.5% Repayable Down Payment Assistance Program" },
    { amount: "3.5% of Purchase Price", name: "California Housing Finance Agency (CalHFA) MyHome Assistance Program" },
    { amount: "3% of Purchase Price", name: "California Housing Finance Agency (CalHFA) CalPLUS FHA with Zero Interest Program (ZIP)" },
    { amount: "3% of Total Loan Amount", name: "California Housing Finance Agency (CalHFA) CalPLUS Conventional with Zero Interest Program (ZIP)" },
  ];

  const additionalPrograms = [
    { amount: "N/A", name: "CalVet Home Loans – First Time Homebuyer (QMB)" },
    { amount: "N/A", name: "CalVet Home Loans – Qualified Veterans Mortgage Bond Program (QVMB)" },
    { amount: "N/A", name: "United States Department of Veterans Affairs – Specially Adapted Housing (SAH) Grant" },
    { amount: "$10,000", name: "Tohono O’odham KI:KI Association (TOKA) Down Payment Assistance (DPA) Program" },
  ];
  

  const handleMilitaryStatusChange = (status) => {
    setMilitaryStatus((prev) =>
      prev.includes(status) ? prev.filter(s => s !== status) : [...prev, status]
    );
  };

  const handleProfessionChange = (profession) => {
    setProfessions((prev) =>
      prev.includes(profession) ? prev.filter(p => p !== profession) : [...prev, profession]
    );
  };

  const handleViewAdditionalPrograms = () => {
    setAssistancePrograms('23+4'); // Update the count
    setShowPrograms(true); // Ensure programs are displayed
    setShowAdditionalPrograms(true); // Show additional programs
  }


  const handleNext = () => {
    setCurrentStep(prev => Math.min(prev + 1, 6)); // Ensure it does not exceed the last step
  };

  const handleBack = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0)); // Ensure it does not go below 0
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentStep(0); // Reset to the first step when closing
  };

  const handleSubmit = (e) => {
    console.log('Form submitted'); // Handle the form submission logic
    handleCloseModal(); // Close the modal after submission
  };


  
  return (
    <div className="text-center mt-10">
      <div className="bg-blue-100 p-6 rounded-lg shadow-lg mx-auto max-w-md">
      <Link to={previousPath} className="text-emerald-600 hover:text-emerald-500 font-semibold">
          ← Back to Analyzer
        </Link>
        <h1 className="text-3xl font-bold mb-4">
          We found{' '}
          <span className="text-emerald-500">
            23
          </span>
          {showAdditionalPrograms && (
            <span className="text-emerald-400 text-shiny-gradient animate-pulse">
              +4
            </span>
          )}{' '}
          assistance programs for you! 🎉
        </h1>
        <p className="text-lg text-gray-700 mb-6">
          Explore the options available to you and your household.
        </p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={() => setShowModal(true)}
            className="bg-emerald-500 text-white px-6 py-2 rounded-lg shadow hover:bg-emerald-600 transition duration-200"
          >
            More Programs
          </button>
          <button
              onClick={() => setShowPrograms(!showPrograms)}
              className="bg-gray-300 text-gray-700 px-6 py-2 rounded-lg shadow hover:bg-gray-400 transition duration-200"
            >
              {showPrograms ? 'Hide Programs' : 'Show me what I have'}
            </button>

        </div>
      </div>

      {showPrograms && (
        <div className="mt-10 max-w-4xl mx-auto">
          <h2 className="text-2xl font-bold text-left mb-6">Programs your home qualifies for:</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {programs.map((program, index) => (
              <div
                key={index}
                className="bg-white p-6 border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition duration-200"
              >
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{program.name}</h3>
                <p className="text-lg text-gray-600">{program.amount}</p>
              </div>
            ))}
          </div>
          {/* Additional Programs Section */}
          {showAdditionalPrograms && (
                <div className="mt-10">
                  <h2 className="text-2xl font-bold text-left mb-6">Additional programs from your provided information:</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {additionalPrograms.map((program, index) => (
                      <div
                        key={index}
                        className="bg-white p-6 border border-gray-200 rounded-lg shadow-lg hover:shadow-xl transition duration-200"
                      >
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">{program.name}</h3>
                        <p className="text-lg text-gray-600">{program.amount}</p>
                      </div>
                    ))}
                  </div>
                </div>
            )}
        </div>
      )}

      {showModal && (
        
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" red={popupRef}>
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
            <h1 className="text-2xl font-bold mb-6">What else might you qualify for? 💸 Let's find out!</h1>
            <form className="space-y-6" onSubmit={handleSubmit}>
              {currentStep === 0 && (
                <div className="p-4 border rounded shadow">
                  <label htmlFor="householdSize" className="block mb-2 font-medium">
                    How many people live in your household? 🧑‍🧑‍🧒‍🧒
                  </label>
                  <p className="text-sm text-gray-500 mb-2">Include yourself, spouse, children, other adults and senior dependents.</p>
                  <input
                    type="number"
                    id="householdSize"
                    value={householdSize}
                    onChange={(e) => setHouseholdSize(e.target.value ? Number(e.target.value) : '')}
                    min="1"
                    className="w-20 p-2 border rounded"
                  />
                </div>
              )}

              {currentStep === 1 && (
                <div className="p-4 border rounded shadow">
                  <label htmlFor="ownsHome" className="block mb-2 font-medium">
                    Do you currently own a home? 🏡
                  </label>
                  <select
                    id="ownsHome"
                    value={ownsHome}
                    onChange={(e) => setOwnsHome(e.target.value)}
                    className="w-full p-2 border rounded"
                  >
                    <option value="">Please make a selection</option>
                    <option value="yes">Yes</option>
                    <option value="never">No, I have never owned a home</option>
                    <option value="within3years">No, but I did within the last 3 years</option>
                    <option value="moreThan3years">No, but I did more than 3 years ago</option>
                  </select>
                </div>
              )}

              {currentStep === 2 && (
                <div className="p-4 border rounded shadow">
                  <label htmlFor="income" className="block mb-2 font-medium">
                    What is your annual household income? 💰
                  </label>
                  <p className="text-sm text-gray-500 mb-2">Include all income for all occupants over 18 years of age.</p>
                  <div className="relative">
                    <span className="absolute left-3 top-1/2 transform -translate-y-1/2">$</span>
                    <input
                      type="number"
                      id="income"
                      value={income}
                      onChange={(e) => setIncome(e.target.value)}
                      className="w-full p-2 pl-8 border rounded"
                      placeholder="0"
                    />
                  </div>
                </div>
              )}

              {currentStep === 3 && (
                <div className="p-4 border rounded shadow">
                  <p className="block mb-2 font-medium">
                    Are you (or a co-borrower) a current or former member of the U.S. military? 🇺🇸
                  </p>
                  <p className="text-sm text-gray-500 mb-2">Select all that apply.</p>
                  <div className="space-y-2">
                    {['Active/Reserve', 'Veteran', 'Surviving Spouse'].map((status) => (
                      <label key={status} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={militaryStatus.includes(status)}
                          onChange={() => handleMilitaryStatusChange(status)}
                          className="mr-2"
                        />
                        {status}
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {currentStep === 4 && (
                <div className="p-4 border rounded shadow">
                  <p className="block mb-2 font-medium">
                    Are you (or a co-borrower) Native American? 
                  </p>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={isNativeAmerican}
                      onChange={(e) => setIsNativeAmerican(e.target.checked)}
                      className="mr-2"
                    />
                    Yes
                  </label>
                </div>
              )}

              {currentStep === 5 && (
                <div className="p-4 border rounded shadow">
                  <p className="block mb-2 font-medium">
                    Do you (or a co-borrower) work in any of these professions?
                  </p>
                  <p className="text-sm text-gray-500 mb-2">Select all that apply.</p>
                  <div className="space-y-2">
                    {['Education 📚', 'Law Enforcement 👨🏼‍⚖️', 'Firefighter 🚒', 'Healthcare 🏥'].map((profession) => (
                      <label key={profession} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={professions.includes(profession)}
                          onChange={() => handleProfessionChange(profession)}
                          className="mr-2"
                        />
                        {profession}
                      </label>
                    ))}
                  </div>
                </div>
              )}

              {currentStep === 6 && (
                <div className="p-4 border rounded shadow">
                  <p className="block mb-2 font-medium">
                    Do you (or a co-borrower or dependent household member) have a disability?
                  </p>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={hasDisability}
                      onChange={(e) => setHasDisability(e.target.checked)}
                      className="mr-2"
                    />
                    Yes
                  </label>
                </div>
              )}

              <div className="flex justify-between mt-4">
                {currentStep > 0 && (
                  <button
                    type="button"
                    onClick={handleBack}
                    className="bg-transparent text-gray-600 font-semibold px-4 py-2 rounded"
                  >
                    Back
                  </button>
                )}
                {currentStep < 6 ? (
                  <button
                    type="button"
                    onClick={handleNext}
                    className="bg-transparent text-emerald-500 font-semibold px-4 py-2 rounded ml-auto"
                  >
                    Next
                  </button>
                ) : (<></>)}
              </div>
            </form>
            {currentStep === 6 && (
              <button
                type="button"
                className="mt-4 bg-transparent text-emerald-500 font-semibold px-4 py-2 rounded"
                onClick={() => {
                  handleSubmit();
                  handleViewAdditionalPrograms();
                }}
                
              >
                View Additional Programs
              </button>
            )}
            {currentStep < 6 && (
                 <button
                 className="mt-4 bg-transparent text-red-500 font-semibold px-4 py-2 rounded"
                 onClick={handleCloseModal}
               >
                 Close
               </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}


