import axios from "axios";
import { db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';

export const fetchPropertyData = async (zillowLink) => {
  try {
    // Extract ZPID from Zillow link
    const zpidMatch = zillowLink.match(/\/(\d+)_zpid/);
    if (!zpidMatch) {
      throw new Error("Invalid Zillow link format");
    }
    const zpid = zpidMatch[1];

    // Check if property exists in Firebase
    const propertyRef = doc(db, 'searchedProperties', zpid);
    const propertyDoc = await getDoc(propertyRef);

    if (propertyDoc.exists()) {
      // console.log('Property found in database');
      return propertyDoc.data();
    }

    // If not in Firebase, make API call
    // console.log('Property not found in database, making API call');
    const encodedUrl = encodeURIComponent(zillowLink);
    const response = await axios.get(
      `https://zillow-working-api.p.rapidapi.com/pro/byurl?url=${encodedUrl}`,
      {
        headers: {
          "x-rapidapi-host": "zillow-working-api.p.rapidapi.com",
          "x-rapidapi-key": "0c53818d82msh10f41a8a10414b9p1744a3jsnc491cd5240d3",
        },
      }
    );

    const data = response.data;
    if (data.propertyDetails.homeStatus === "FOR_RENT") {
      throw new Error("The property is currently listed for rent.");
    }

    // Format the property data
    const propertyData = {
      photos: data.propertyDetails.originalPhotos.slice(0, 2).map(photo => 
        photo.mixedSources.jpeg.find(j => j.width === 800)?.url || null
      ), // First 2 photos
      insights: data.propertyDetails.homeInsights?.[0]?.insights?.[0]?.phrases,
      address: `${data.propertyDetails.address.streetAddress}, ${data.propertyDetails.address.city}, ${data.propertyDetails.address.state} ${data.propertyDetails.address.zipcode}`,
      streetAddress: data.propertyDetails.address.streetAddress,
      city: data.propertyDetails.address.city,
      price: data.propertyDetails.price,
      homeType: data.propertyDetails.homeType,
      squareFootage: data.propertyDetails.livingArea,
      bedrooms: data.propertyDetails.bedrooms,
      bathrooms: data.propertyDetails.bathrooms,
      yearBuilt: data.propertyDetails.yearBuilt,
      listingDate: `${data.propertyDetails.daysOnZillow} days ago`,
      zipCode: data.propertyDetails.address.zipcode,
      zestimate: data.propertyDetails.zestimate,
      zpid: data.propertyDetails.zpid,
      zillowUrl: data.zillowURL,
      monthlyHoaFee: data.propertyDetails.monthlyHoaFee,
      similarHomes: data.propertyDetails.collections.modules
        .filter(module => module.name === "Similar homes")
        .flatMap(module => module.propertyDetails.map(home => ({
          zpid: home.zpid,
          price: home.price,
          bedrooms: home.bedrooms,
          bathrooms: home.bathrooms,
          livingArea: home.livingArea,
          address: `${home.address.streetAddress}, ${home.address.city}, ${home.address.state} ${home.address.zipcode}`,
          zillowUrl: home.hdpUrl,
          homeStatus: home.homeStatus,
        }))),
      timestamp: new Date(),
    };

    // Store the data in Firebase
    await setDoc(propertyRef, propertyData);

    return propertyData;
  } catch (err) {
    console.error(err);
    if (err.message === "The property is currently listed for rent.") {
      throw err;
    }
    throw new Error("Failed to fetch property data. Please check the Zillow link and try again.");
  }
};