import axios from 'axios';
import { auth } from '../firebase';

const API_URL = process.env.NODE_ENV === 'production' 
  ? '/api'  // For local production testing, change to ? 'http://localhost:5001' 
  : 'http://localhost:5001/api'; // For development

// Helper function to get browser info.
const getBrowserInfo = () => {
  const userAgent = navigator.userAgent;
  let browser = 'Other';
  let browser_version = '';
  
  if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
    browser = 'Safari';
    browser_version = userAgent.match(/Version\/([0-9.]+)/)?.[1] || '';
  } else if (userAgent.includes('Firefox')) {
    browser = 'Firefox';
    browser_version = userAgent.match(/Firefox\/([0-9.]+)/)?.[1] || '';
  } else if (userAgent.includes('Chrome')) {
    browser = 'Chrome';
    browser_version = userAgent.match(/Chrome\/([0-9.]+)/)?.[1] || '';
  } else if (userAgent.includes('Edge')) {
    browser = 'Edge';
    browser_version = userAgent.match(/Edge\/([0-9.]+)/)?.[1] || '';
  }

  return {
    $browser: browser,
    $browser_version: browser_version,
    $os: userAgent.includes('Mac') ? 'Mac OS X' : 
         userAgent.includes('Windows') ? 'Windows' : 
         userAgent.includes('Linux') ? 'Linux' : 'Other',
    $referring_domain: document.referrer ? new URL(document.referrer).hostname : '$direct',
    $current_url: window.location.href,
    $referrer: document.referrer || '$direct'
  };
};

export const trackEvent = async (eventName, properties = {}) => {
  try {
    const user = auth.currentUser;
    const browserInfo = getBrowserInfo();
    
    const response = await axios.post(`${API_URL}/track`, {
      eventName,
      properties: {
        ...browserInfo,
        ...properties,
      },
      userId: user?.email || null
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.data.success) {
      throw new Error('Tracking failed');
    }

    console.log('Track response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error tracking event:', error.response?.data || error.message);
    throw error;
  }
};
