import React from 'react';
import emailjs from 'emailjs-com';

// Custom link for PropertyAnalysis.js and Chatbot.js 
export const CustomLink = ({ href, children }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
  >
    {children}
  </a>
);


// PropertyAnalysis.js, PropertyComparison.js, MortgageCalculator.js, ComparativeMarketAnalysis.js
export const formatNumber = (num) => {
  if (num === undefined || num === null) {
    return "";
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// Format home status
export const formatHomeStatus = (status) => {
  if (!status) return "N/A";
  return status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};

// Progress bar color based on progress percentage in Closing.js
export const getProgressBarColor = (progress) => {
  if (progress < 14.29) return 'bg-red-500';
  if (progress < 28.57) return 'bg-gradient-to-r from-red-500 to-orange-500';
  if (progress < 42.86) return 'bg-gradient-to-r from-orange-500 to-yellow-500';
  if (progress < 57.14) return 'bg-gradient-to-r from-yellow-500 to-yellow-300';
  if (progress < 71.43) return 'bg-gradient-to-r from-yellow-300 to-green-300';
  if (progress < 85.71) return 'bg-gradient-to-r from-green-300 to-green-400';
  if (progress < 100) return 'bg-gradient-to-r from-green-300 to-green-500';
  return 'bg-green-500';
};

// Checklist utils for InspectionChecklist.js and Closing.js
export const renderChecklistItem = (id, checked, onChange, label) => (
  <div key={id} className="flex items-center">
    <input
      type="checkbox"
      id={id}
      checked={checked}
      onChange={onChange}
      className="mr-2 form-checkbox h-5 w-5 text-blue-600 cursor-pointer"
    />
    <label htmlFor={id} className="text-gray-700 cursor-pointer">
      {label}
    </label>
  </div>
);

// Subtitles (preceding a : ) are bolded and link to Zillow
// Used in PropertyAnalysis.js
export const LabelValue = ({ label, value }) => (
  <p className="mb-1">
    <span className="font-bold">{label}:</span>{" "}
    {typeof value === 'string' && value.startsWith('http') ? (
      <CustomLink href={value}>Link to Zillow</CustomLink>
    ) : (
      value
    )}
  </p>
);

// Email sending utility functions
export const sendFeedbackEmail = async (feedback, emails, consensus) => {
  try {
    const response = await emailjs.send(
      'service_xmfqyqq',
      'template_fvg1wlb',
      { 
        feedback: feedback, 
        emails: emails,
        consensus: consensus
      }, 
      'zL-_TcCDwgOZkQkXr'
    );
    console.log('Feedback sent successfully:', response);
  } catch (error) {
    console.error('Error sending feedback:', error);
  }
};

export const sendSignUpEmail = async (email) => {
  try {
    const response = await emailjs.send(
      'service_zy513uc',
      'template_fvg1wlb',
      { 
        email: email
      }, 
      'zL-_TcCDwgOZkQkXr'
    );
    console.log('Sign-up email sent successfully:', response);
  } catch (error) {
    console.error('Error sending sign-up email:', error);
  }
};

export const MaxWidthLayout = ({ children }) => {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      {children}
    </div>
  );
};

// Utility function to format the home type string
// SINGLE_FAMILY -> Single Family
export const formatHomeType = (homeType) => {
  if (!homeType) return "Not given";

  return homeType
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};