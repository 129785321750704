import React, { useState } from 'react';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { sendFeedbackEmail } from '../utils/CustomComponents';
import { trackEvent } from '../utils/tracking';
import { getAuth } from 'firebase/auth';
import mixpanel from 'mixpanel-browser';

const FeedbackForm = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [emails, setEmail] = useState("");
  const [consensus, setConsensus] = useState("");
  const [selectedButton, setSelectedButton] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const togglePopup = async () => {
    setPopupOpen(!isPopupOpen);
    if (!isPopupOpen) {
      try {
        mixpanel.track('Feedback Form Opened');
      } catch (error) {
        await trackEvent('Feedback Form Opened');
      }
    }
  };

  const closePopupOutside = (e) => {
    if (e.target === e.currentTarget) {
      setPopupOpen(false);
    }
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleLike = async () => {
    setConsensus('Thumbs Up');
    setSelectedButton('like');
    try {
      mixpanel.track('Feedback Positive Selected', {
        sentiment: 'positive'
      });
    } catch (error) {
      await trackEvent('Feedback Positive Selected', {
        sentiment: 'positive'
      });
    }
  };

  const handleDislike = async () => {
    setConsensus('Thumbs Down');
    setSelectedButton('dislike');
    try {
      mixpanel.track('Feedback Negative Selected', {
        sentiment: 'negative'
      });
    } catch (error) {
      await trackEvent('Feedback Negative Selected', {
        sentiment: 'negative'
      });
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmitFeedback = async (event) => {
    event.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    const auth = getAuth();
    const user = auth.currentUser;

    const eventProperties = {
      feedback_text: feedback,
      email: emails,
      sentiment: consensus,
      userId: user?.email || emails,
      authenticated: !!user
    };

    try {
      mixpanel.track('Feedback Submitted', eventProperties);
      await sendFeedbackEmail(feedback, emails, consensus);

      setShowThankYou(true);
      setTimeout(() => {
        setShowThankYou(false);
        setPopupOpen(false);
        setConsensus('');
        setFeedback('');
        setEmail('');
        setSelectedButton('');
      }, 1500);

    } catch (error) {
      console.error('Client-side tracking failed:', error);
      await trackEvent('Feedback Submitted', eventProperties);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <button 
        onClick={togglePopup} 
        className="fixed bottom-5 right-5 bg-emerald-600 text-white px-6 py-3 rounded-full hover:bg-emerald-500 transition duration-300 shadow-lg"
      >
        Feedback
      </button>
      
      {isPopupOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" 
          onClick={closePopupOutside}
        >
          <div 
            className="bg-white p-6 rounded-lg shadow-xl w-full max-w-sm sm:max-w-md md:max-w-lg mx-4" 
            onClick={(e) => e.stopPropagation()}
          >
            {showThankYou ? (
              <h2 className="text-2xl font-bold mb-4 text-center">
                Thank you for submitting!
              </h2>
            ) : (
              <>
                <h2 className="text-2xl font-bold mb-4 text-center">
                  What do you think?
                </h2>
                <div className="flex justify-center mb-4 space-x-4">
                  <button
                    type="button"
                    className={`bg-emerald-100 text-emerald-600 hover:bg-emerald-200 hover:text-emerald-700 p-4 rounded-full 
                      ${selectedButton === 'like' ? 'border-2 border-emerald-600 ring-emerald-600 ring-offset-2 ring-2 rounded-full' : ''}`}
                    onClick={handleLike}
                  >
                    <FaThumbsUp size={28} />
                  </button>
                  <button
                    type="button"
                    className={`bg-red-100 text-red-600 hover:bg-red-200 hover:text-red-700 p-4 rounded-full 
                      ${selectedButton === 'dislike' ? 'border-2 border-red-600 ring-red-600 ring-offset-2 ring-2 rounded-full' : ''}`}
                    onClick={handleDislike}
                  >
                    <FaThumbsDown size={28} />
                  </button>
                </div>
                
                <div className="mb-4 text-center">
                  <p>Give feedback below!</p>
                  <p>
                    or <a href="tel:845-535-9673" className="text-blue-600 hover:underline">Call Us</a> at 845-535-9673
                  </p>
                </div>

                <form onSubmit={handleSubmitFeedback} className="space-y-4">
                  <textarea
                    className="w-full h-40 p-2 border-2 border-gray-300 rounded resize-none focus:border-emerald-500 focus:outline-none"
                    value={feedback}
                    onChange={handleFeedbackChange}
                    placeholder="Your feedback..."
                    required
                  />
                  
                  <p className="text-center">Email Address</p>
                  <input
                    type="email"
                    className="w-full h-11 p-2 border-2 border-gray-300 rounded focus:border-emerald-500 focus:outline-none"
                    value={emails}
                    onChange={handleEmailChange}
                    placeholder="your@email.com"
                    required
                  />
                  
                  <button 
                    type="submit" 
                    disabled={isSubmitting}
                    className={`w-full py-2 rounded transition duration-300 ${
                      isSubmitting 
                        ? 'bg-gray-400 cursor-not-allowed' 
                        : 'bg-emerald-600 hover:bg-emerald-500 text-white'
                    }`}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                  </button>
                </form>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackForm;