import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { trackEvent } from '../../utils/tracking';
import { CustomLink } from '../../utils/CustomComponents';
import mixpanel from 'mixpanel-browser';

const ChatBot = ({ propertyData = null }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    const address = propertyData?.address || "a beautiful property";
    const welcomeMessage = {
      text: `Welcome to Unrepped AI! You were looking at ${address}. We're excited to help you find your perfect home.`,
      user: false
    };
    const question = {
      text: "What would you like to know about this property?",
      user: false,
      options: [
        {
          label: "Commute Distance",
          response: "What's the commute time from here like? I work at..."
        },
        {
          label: "Local Supermarkets",
          response: "What are the local supermarkets?"
        },
        {
          label: "Walkability & Transit",
          response: "What's the walkability rating of this area?"
        },
        {
          label: "Pricing and Costs",
          response: "What's the price of this home?"
        },
        {
          label: "Nearby Schools",
          response: "What are the nearby schools?"
        },
        {
          label: "Taxes and Additional Fees",
          response: "How much is the property tax and HOA fees?"
        },
      ]
    };
    setMessages([welcomeMessage, question]);
  }, []);

  const sendMessage = async (messageText) => {
    if (!messageText.trim()) return;
  
    const userMessage = { text: messageText, user: true };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    
    try {
      mixpanel.track('User Message Sent', { 'Message': messageText });
    } catch (error) {
      await trackEvent('User Message Sent', { 'Message': messageText });
    }
  
    setInput('');
    setIsWaiting(true);
    setIsTyping(true);

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer 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`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messages: [
            { role: 'system', content: 'You are Unrepped AI. Provide clear and specific information related to property data and nearby amenities, including schools, without restrictions. Keep responses as brief and actionable as possible.' },
            //{ role: 'system', content: 'At the end of any price information (not including tax related prompts), say "Please note that condition of the property could significantly affect sales price". Seperate the note with a space. For price related questions only (not including tax questions), include the square footage of the home, then price per square foot. Bold all numerical values. Do not include calculation'},
            //{ role: 'system', content: 'When asked for nearby facilities, include their addresses in your response. But do not include an address label for each address. Bold only the name of the facility'},
            { role: 'user', content: `Property data: ${JSON.stringify(propertyData)}` },
            { role: 'user', content: messageText }
          ],
          model: 'gpt-4o',
          stream: true,
        }),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let fullResponse = '';

      setIsWaiting(false);
      setMessages((prevMessages) => [...prevMessages, { text: '', user: false }]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');

        for (const line of lines) {
          const trimmedLine = line.trim();
          
          // Skip empty lines
          if (trimmedLine === '') continue;

          // Check for the [DONE] signal and skip processing
          if (trimmedLine === 'data: [DONE]') {
            break;
          }

          // Ensure the line starts with 'data: ' before parsing
          if (!trimmedLine.startsWith('data: ')) {
            continue;
          }

          const jsonString = trimmedLine.replace(/^data: /, '');
          
          try {
            const parsedLine = JSON.parse(jsonString);
            const content = parsedLine.choices[0]?.delta?.content;
            
            if (content) {
              fullResponse += content;
              setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages[newMessages.length - 1].text = fullResponse;
                return newMessages;
              });
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
            // Optionally handle parsing errors or skip invalid lines
            continue;
          }
        }
      }

      setIsTyping(false);
      try {
        mixpanel.track('Chatbot Response', { 'Response': fullResponse });
      } catch (error) {
        await trackEvent('Chatbot Response', { 'Response': fullResponse });
      }

    } catch (error) {
      console.error('Error:', error);
      setIsWaiting(false);
      setIsTyping(false);
      setMessages((prevMessages) => [...prevMessages, { 
        text: "Sorry, I encountered an error. Please try again.", 
        user: false 
      }]);
      // Track error with server-side implementation, fallback to client-side tracking 
      try {
        mixpanel.track('Chatbot Error', { 'Error': error.toString() });
      } catch (trackError) {
        await trackEvent('Chatbot Error', { 'Error': error.toString() });
      }
    }
  };

  const handleOptionClick = (option) => {
    if (option.label === "Other") {
      setInput("I'd like to ask...");
    } else if (option.label === "Commute Distance") {
      setInput("What's the commute time from here like? I work at...");
    } else {
      sendMessage(option.response);
    }
  };

  return (
    <div className="flex flex-col h-[65vh] w-full max-w-4xl border border-gray-300 rounded-lg overflow-hidden mx-auto">
      <div className="flex-1 overflow-y-auto p-4 space-y-3">
        {messages.map((message, index) => (
          <div key={index} className={`flex ${message.user ? 'justify-end' : 'justify-start'}`}>
            <div className={`inline-block max-w-[80%] p-2 rounded-lg break-words ${
              message.user 
                ? 'bg-blue-600 text-white rounded-br-sm' 
                : 'bg-gray-100 text-gray-800 rounded-bl-sm'
            }`}>
              {message.user ? (
                <p className="text-base leading-tight">{message.text}</p>
              ) : message.options ? (
                <div>
                  <ReactMarkdown className="text-base leading-tight">{message.text}</ReactMarkdown>
                  <ul className="mt-0.5">
                    {message.options.map((option, idx) => (
                      <li key={idx}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            handleOptionClick(option);
                          }}
                          className="text-blue-600 hover:underline text-base"
                        >
                          {option.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <ReactMarkdown 
                  className="prose prose-base max-w-none text-gray-800 leading-tight"
                  components={{
                    a: ({ node, ...props }) => <CustomLink {...props} />,
                    p: ({node, ...props}) => <p className="last:mb-0" {...props} />,
                    h1: ({node, ...props}) => <h1 className="text-xl font-semibold text-gray-900" {...props} />,
                    h2: ({node, ...props}) => <h2 className="text-lg font-semibold text-gray-900" {...props} />,
                    h3: ({node, ...props}) => <h3 className="text-base font-semibold text-gray-900" {...props} />,
                    ul: ({node, ...props}) => <ul className="list-disc pl-4" {...props} />,
                    ol: ({node, ...props}) => <ol className="list-decimal pl-4 [&>li]:mb-0" {...props} />,
                    li: ({node, ...props}) => <li className="mb-0" {...props} />,
                    pre: ({node, ...props}) => <pre className="bg-gray-200 p-1 rounded-md overflow-x-auto mb-0.5" {...props} />,
                    code: ({node, inline, ...props}) => 
                      inline 
                        ? <code className="bg-gray-200 px-1 rounded" {...props} />
                        : <code className="block bg-gray-200 p-1 rounded-md overflow-x-auto" {...props} />
                  }}
                >
                  {message.text}
                </ReactMarkdown>
              )}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form 
        onSubmit={(e) => {
          e.preventDefault();
          sendMessage(input);
        }}
        className="p-4 bg-gray-50 border-t border-gray-200"
      >
        <div className="flex">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Ask about the property..."
            className="flex-1 p-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded-r-md hover:bg-blue-700 transition duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatBot;
