import React, { useState, useEffect, useRef } from 'react';
import { signOut, getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { useLocation } from 'react-router-dom';

export default function Component({ user }) {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const auth = getAuth();
  const email = user?.email || "";

  const dropdownRef = useRef(null);
  const location = useLocation();

  // Modified toggleDropdown function
  const toggleDropdown = () => {
    if (isDropdownOpen) {
     setDropdownOpen(false);
    
    } else {
      setDropdownOpen(true);
    }
    
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };

  const isActive = (path) => {
     return location.pathname === path;
   };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSignOut = () => {
    signOut(auth).then(() => {
      window.location.href = '/signin';
    }).catch((error) => {
      console.error('Error signing out:', error);
    });
  };

  const handleChangePassword = async () => {
    if (!email) {
      setError('No email associated with the account.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage('Please check your inbox for further instructions.');
      setError('');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <>
      <nav className="bg-white shadow-md fixed top-0 left-0 right-0 z-50">
        <div className="mx-auto max-w-4xl px-4">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                className="relative inline-flex items-center justify-center rounded-md p-2 bg-white text-gray-500 hover:bg-gray-100 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <span className="sr-only">Open main menu</span>
                {isMobileMenuOpen ? (
                  <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                  </svg>
                )}
              </button>
            </div>

            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center">
                <img className="h-8 w-auto" src="/logo_white.png" alt="Unrepped" />
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex space-x-4">
                  <a href="/userapp" className={`rounded-md px-3 py-2 text-sm font-medium ${isActive('/userapp') ? 'bg-gray-600 text-white' : 'text-gray-700 hover:bg-gray-500 hover:text-white'} transition-colors duration-200`}>Dashboard</a>
                  <a href="/userapp/history" className={`rounded-md px-3 py-2 text-sm font-medium ${isActive('/userapp/history') ? 'bg-gray-600 text-white' : 'text-gray-700 hover:bg-gray-500 hover:text-white'} transition-colors duration-200`}>Searched Properties</a>
                  <a href="/userapp/preferences" className={`rounded-md px-3 py-2 text-sm font-medium ${isActive('/userapp/preferences') ? 'bg-gray-600 text-white' : 'text-gray-700 hover:bg-gray-500 hover:text-white'} transition-colors duration-200`}>Preferences</a>
                </div>
              </div>
            </div>

            <div className="relative inline-block text-left">
              <button
                onClick={toggleDropdown}
                className="relative rounded-full bg-gray-200 p-1 text-gray-700 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 focus:ring-offset-gray-100"
              >
                <span className="sr-only">Open user menu</span>
                <svg className="h-8 w-8 rounded-full bg-gray-200 p-1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path fillRule="evenodd" d="M12 12c2.7 0 4.8-2.1 4.8-4.8S14.7 2.4 12 2.4 7.2 4.5 7.2 7.2 9.3 12 12 12zm0 2.4c-3.2 0-9.6 1.6-9.6 4.8v2.4h19.2v-2.4c0-3.2-6.4-4.8-9.6-4.8z" clipRule="evenodd" />
                </svg>
              </button>

              <div
                ref={dropdownRef}
                className={`origin-top-right absolute right-0 mt-2 w-64 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50 transition-all duration-300 ease-in-out transform ${isDropdownOpen ? 'scale-100' : 'scale-95 opacity-0'}`}
                style={{ width: '300px', height: '250px', display: isDropdownOpen ? 'block' : 'none' }}
              >
                <div className="py-3 px-4 border-b border-gray-200 text-center">
                  <p className="text-gray-700 text-sm">{email}</p>
                </div>

                <div className="p-4 space-y-3">
                  <button
                    onClick={handleChangePassword}
                    className="w-full px-4 py-2 bg-white text-gray-700 rounded-lg border border-gray-900 hover:bg-gray-100 transition duration-300 shadow text-lg font-semibold"
                    style={{ height: '45px' }}
                  >
                    Reset Password
                  </button>

                  <button
                    onClick={handleSignOut}
                    className="w-full px-4 py-2 bg-white text-gray-700 rounded-lg border border-gray-900 hover:bg-gray-100 transition duration-300 shadow text-lg font-semibold"
                    style={{ height: '45px' }}
                  >
                    Sign Out
                  </button>

                  {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
                  {successMessage && <p className="text-green-500 mt-2 text-left">{successMessage}</p>}
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobileMenuOpen && (
          <div className="sm:hidden" id="mobile-menu">
            <div className="space-y-1 px-2 pb-3 pt-2 bg-white">
              <a href="/userapp" className={`block rounded-md px-3 py-2 text-base font-medium ${isActive('/userapp') ? 'bg-gray-600 text-white' : 'text-gray-900 hover:bg-gray-500 hover:text-white'} transition-colors duration-200`}>Dashboard</a>
              <a href="/userapp/history" className={`block rounded-md px-3 py-2 text-base font-medium ${isActive('/userapp/history') ? 'bg-gray-600 text-white' : 'text-gray-900 hover:bg-gray-500 hover:text-white'} transition-colors duration-200`}>Searched Properties</a>
              <a href="/userapp/preferences" className={`block rounded-md px-3 py-2 text-base font-medium ${isActive('/userapp/preferences') ? 'bg-gray-600 text-white' : 'text-gray-900 hover:bg-gray-500 hover:text-white'} transition-colors duration-200`}>Preferences</a>
            </div>
          </div>
        )}
      </nav>
    </>
  );
}