import React from 'react';
import { trackEvent } from '../utils/tracking';
import mixpanel from 'mixpanel-browser';

const FeatureButton = ({ name, icon, onClick, isActive, number }) => {
  const handleClick = async () => {
    try {
      // Try client-side tracking first
      mixpanel.track('Widget Clicked', { 'Widget Name': name });
      console.log('Widget clicked:', name);
    } catch (error) {
      // Fallback to server-side tracking
      console.log('Error with client-side tracking:', error);
      console.log('Falling back to server-side tracking...');
      await trackEvent('Widget Clicked', { 'Widget Name': name });
    }
    onClick();
  };

  return (
    <button
      onClick={handleClick}
      className={`
        flex flex-col items-center justify-center p-4 rounded-lg transition-all duration-300 relative
        ${isActive
          ? 'bg-emerald-700 text-white shadow-lg hover:-translate-y-1'
          : 'bg-emerald-600 text-white hover:bg-emerald-500 hover:shadow-md hover:-translate-y-1'
        }
      `}
    >
      {/* Number Badge */}
      <span className="absolute top-2 left-2 bg-white text-emerald-600 rounded-full w-6 h-6 flex items-center justify-center text-sm font-semibold">
        {number}
      </span>
      
      <div className="text-3xl mb-2">{icon}</div>
      <span className="text-sm font-medium text-center">{name}</span>
    </button>
  );
};

export default FeatureButton;