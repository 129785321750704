import { fetchPropertyData } from "../../utils/propertyApi";

export const analyzeProperty = async (zillowLink) => {
  try {
    const data = await fetchPropertyData(zillowLink);
    return data;
  } catch (error) {
    throw error;
  } 
};

export const calculateMonthlyCosts = (propertyData, includeInternetTv = true, interestRates) => {
  if (!propertyData || !propertyData.price) return null;
  const price = typeof propertyData.price === "string" ? parseFloat(propertyData.price.replace(/[^0-9.-]+/g, "")) : propertyData.price;
  const loanAmount = price * 0.8; // 20% down payment
  
  // Corrected monthlyInterestRate calculation
  const monthlyInterestRate = (interestRates.rate30Year || 7) / 1200;
  const numberOfPayments = 30 * 12; // 30-year fixed

  const mortgagePayment = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

  const annualPropertyTax = price * 0.01; // 1% annual property tax rate (estimated)
  const monthlyPropertyTax = annualPropertyTax / 12;

  const homeInsurance = 518; // estimated
  const mortgageInsurance = 641; // estimated
  const hoaFees = propertyData.monthlyHoaFee || 0; // Handle null

  const avgUtilitiesCostPerSqFt = 0.2; // $0.20 per square foot per month
  const squareFootage = typeof propertyData.squareFootage === "string" ? parseFloat(propertyData.squareFootage.replace(/[^0-9.-]+/g, "")) : propertyData.squareFootage;
  const estimatedUtilities = squareFootage * avgUtilitiesCostPerSqFt;

  const internetTV = 100; // estimated
  return {
    mortgage: mortgagePayment.toFixed(2),
    propertyTax: monthlyPropertyTax.toFixed(2),
    homeInsurance: homeInsurance.toFixed(2),
    mortgageInsurance: mortgageInsurance.toFixed(2),
    hoaFees: hoaFees.toFixed(2), // Added HOA Fees
    utilities: estimatedUtilities.toFixed(2),
    internetTV: includeInternetTv ? internetTV.toFixed(2) : "0.00",
    total: (
      mortgagePayment +
      monthlyPropertyTax +
      homeInsurance +
      mortgageInsurance +
      estimatedUtilities +
      (includeInternetTv ? internetTV : 0) +
      hoaFees // Included HOA Fees in Total
    ).toFixed(2),
    city: propertyData.city || "Unknown",
  };
};

export const calculateSavings = (propertyData, interestRates) => {
  if (!propertyData || !propertyData.price) return null;
  const price = typeof propertyData.price === "string" ? parseFloat(propertyData.price.replace(/[^0-9.-]+/g, "")) : propertyData.price;
  const agentFee = price * 0.03;
  
  // Corrected monthlyInterestRate calculation
  const monthlyInterestRate = (interestRates.rate30Year || 7) / 1200;
  const numberOfPayments = 30 * 12;
  const monthlySavings = (agentFee * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
  const totalSavings = monthlySavings * numberOfPayments;
  return {
    agentFee: agentFee.toFixed(2),
    monthlySavings: monthlySavings.toFixed(2),
    totalSavings: totalSavings.toFixed(2),
  };
};

export const calculateCashToClose = (propertyData, financeAgentFee = false) => {
  if (!propertyData || !propertyData.price) return null;

  const price = typeof propertyData.price === "string" ? parseFloat(propertyData.price.replace(/[^0-9.-]+/g, "")) : propertyData.price;

  const downPayment = price * 0.20; // 20% down payment
  const agentFee = price * 0.03; // 3% agent fee
  const closingCosts = price * 0.05; // 5% closing costs

  const total = downPayment + closingCosts + (financeAgentFee ? 0 : agentFee);

  return {
    total: total.toFixed(2),
    downPayment: downPayment.toFixed(2),
    closingCosts: closingCosts.toFixed(2),
    agentFee: financeAgentFee ? 0 : agentFee.toFixed(2),
    financeAgentFee,
    city: propertyData.city || "Unknown",
  };
};
