import React, { useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaMoneyBillWave, FaSearchLocation, FaPiggyBank, FaCheckCircle, FaArrowLeft } from 'react-icons/fa';
import { handleFinancingChoice, handleFoundHomeChoice, handlePriorityChoice, handleBack, handleExploreSignUp, handleExploreApp } from './WalkthroughService';

const buttonClasses = {
  primary: "px-6 py-3 font-medium bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition duration-300 transform hover:scale-105",
  secondary: "px-6 py-3 font-medium bg-emerald-100 text-emerald-700 rounded-full hover:bg-emerald-200 transition duration-300 transform hover:scale-105",
  light: "px-6 py-3 font-medium bg-emerald-300 text-white rounded-full hover:bg-emerald-400 transition duration-300 transform hover:scale-105", // New button class
};

const WalkthroughView = () => {
  const [step, setStep] = useState(1);
  const [hasFinancing, setHasFinancing] = useState(null);
  const [foundHome, setFoundHome] = useState(null);
  const [priority, setPriority] = useState(null);
  const navigate = useNavigate();

  const totalSteps = 5; // Updated total steps to include the new step
  const currentStep = step === 1.5 ? 1 : step;
  const progress = (currentStep / totalSteps) * 100;

  const StepIcon = useCallback(({ step }) => {
    const iconClasses = "text-3xl text-emerald-600 mx-auto";
    switch(step) {
      case 1: 
        return <FaMoneyBillWave className={`${iconClasses} mx-auto`} />;
      case 1.5: 
        return <FaCheckCircle className={`${iconClasses} mx-auto`} />;
      case 2: 
        return <FaHome className={`${iconClasses} mx-auto`} />;
      case 2.5: 
        return <FaCheckCircle className={`${iconClasses} mx-auto`} />;
      case 3: 
        return <FaSearchLocation className={`${iconClasses} mx-auto`} />;
      case 4: 
        return <FaPiggyBank className={`${iconClasses} mx-auto`} />;
      default: 
        return null;
    }
  }, []);

  const Button = ({ onClick, variant = 'primary', children }) => (
    <button
      onClick={onClick}
      className={buttonClasses[variant]}
    >
      {children}
    </button>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-emerald-50 via-teal-50 to-cyan-50 flex flex-col items-center justify-center">
      <div className="w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden relative">
        {/* Back Button */}
        {(currentStep === 1 || step > 1) && (
          <button
            onClick={() => currentStep === 1 ? navigate('/') : handleBack(step, setStep, { resetFoundHome: () => setFoundHome(null), resetPriority: () => setPriority(null) })}
            className="absolute bg-white hover:bg-emerald-100 top-4 left-4 text-emerald-600 hover:text-emerald-800 focus:outline-none"
            aria-label="Go Back"
          >
            <FaArrowLeft className="w-5 h-5" />
          </button>
        )}
        {/* Progress Bar */}
        <div className="h-2 bg-emerald-200">
          <div 
            className="h-full bg-emerald-600 transition-all duration-500 ease-in-out"
            style={{ width: `${progress}%` }}
          ></div>
        </div>
        <div className="p-8">
          <div className="flex justify-center mb-6">
            <StepIcon step={currentStep} />
          </div>
          
          {step === 1 && (
            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-center text-emerald-700">
                Welcome to Your Home Buying Journey! 🏡✨
              </h2>
              <p className="text-center text-gray-600">
                Let's start with an important question about financing.
              </p>
              <h3 className="text-2xl font-semibold text-center text-emerald-600">
                Do you have financing in place?
              </h3>
              <div className="flex justify-center space-x-4">
                <Button onClick={() => handleFinancingChoice(true, setStep, setHasFinancing)}>
                  Yes, I'm all set!
                </Button>
                <Button onClick={() => handleFinancingChoice(false, setStep, setHasFinancing)} variant="secondary">
                  Not yet
                </Button>
              </div>
            </div>
          )}

          {step === 1.5 && (
            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-center text-emerald-700">
                Financing: Your Key to Success! 🔑
              </h2>
              <p className="text-center text-gray-600">
                Getting pre-approved for a mortgage is a crucial step in your home buying journey. It helps you understand your budget and makes your offers more competitive.
              </p>
              <p className="text-center text-gray-600">
                Don't worry if you're not there yet - Unrepped can guide you through the process! 🛠️
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() => setStep(2)}
                  className="px-6 py-3 font-medium bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition duration-300 transform hover:scale-105"
                >
                  Got it!
                </button>
              </div>
            </div>
          )}

          {step === 2 && (
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-center">
                Have you already found a home? 🏠🔍
              </h2>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={() => handleFoundHomeChoice(true, setStep, setFoundHome)}
                  className="px-6 py-3 font-medium bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition duration-300 transform hover:scale-105"
                >
                  Yes!
                </button>
                <button
                  onClick={() => handleFoundHomeChoice(false, setStep, setFoundHome)}
                  className="px-6 py-3 font-medium bg-emerald-100 text-emerald-700 rounded-full hover:bg-emerald-200 transition duration-300 transform hover:scale-105"
                >
                  Not yet!
                </button>
              </div>
            </div>
          )}

          {step === 2.5 && (
            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-center text-emerald-700">
                Great! You're Ahead of the Game! 🎉🚀
              </h2>
              <p className="text-center text-gray-600">
                Unrepped offers tools to help you at every stage of the homebuying journey, from analyzing properties to closing the deal.
              </p>
              <div className="flex justify-center space-x-4">
              <button
                  onClick={() => handleExploreSignUp(step, navigate)}
                  className="px-6 py-3 font-medium bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition duration-300 transform hover:scale-105"
                >
                  Create account
                </button>
                <button
                  onClick={() => handleExploreApp(step, navigate)}
                  className="px-6 py-3 font-medium bg-emerald-100 text-emerald-700 rounded-full hover:bg-emerald-200 transition duration-300 transform hover:scale-105"
                >
                  Explore Unrepped
                </button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-center text-emerald-700">
                Not a problem! Let's focus on what matters most to you in your new home. 🏡❤️
              </h2>
              <p className="text-center text-gray-600">
                What's your top priority?
              </p>
              <div className="flex flex-col space-y-4">
                <button
                  onClick={() => handlePriorityChoice('location', setStep, setPriority)}
                  className="px-6 py-3 font-medium bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition duration-300 transform hover:scale-105"
                >
                  Location (school, transport, amenities)
                </button>
                <button
                  onClick={() => handlePriorityChoice('home details', setStep, setPriority)}
                  className="px-6 py-3 font-medium bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition duration-300 transform hover:scale-105"
                >
                  Home Details (yard, patio, space)
                </button>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="space-y-6">
              <h2 className="text-3xl font-bold text-center text-emerald-700">
                Before You Go,<br />Remember Hidden Costs! 💸🏠
              </h2>
              <p className="text-center text-gray-600">
                When buying a home, it's important to consider all costs: taxes, fees, maintenance, and repairs.
              </p>
              <p className="text-center text-gray-600">
                Don't worry! Unrepped has tools to help you estimate these costs and make informed decisions.
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  onClick={() => handleExploreSignUp(step, navigate)}
                  className="px-6 py-3 font-medium bg-emerald-500 text-white rounded-full hover:bg-emerald-600 transition duration-300 transform hover:scale-105"
                >
                  Create account
                </button>
                <button
                  onClick={() => handleExploreApp(step, navigate)}
                  className="px-6 py-3 font-medium bg-emerald-100 text-emerald-700 rounded-full hover:bg-emerald-200 transition duration-300 transform hover:scale-105"
                >
                  Explore Unrepped
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WalkthroughView;
