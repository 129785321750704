'use client'

import { useState } from 'react';
import { auth, provider } from '../../../../firebase'; // Adjust the import path as necessary
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";

export default function SignIn() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Reset the error state

    try {
      // Firebase sign in
      await signInWithEmailAndPassword(auth, username, password);
      
      // On successful login, redirect to the app
      window.location.href = '/userapp';
    } catch (err) {
      // Handle errors
      if (err.code === 'auth/invalid-credential') {
        setError('Invalid username or password, please try again.'); 
      } else {
        setError('A server error has occurred. Please try again.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      // Firebase Google sign-in
      await signInWithPopup(auth, provider);
      
      // On successful login, redirect to the app
      window.location.href = '/userapp';
    } catch (err) {
      setError('Google sign-in failed. Please try again.');
    }
  };

  return (
    <div className="min-h-screen bg-emerald-50 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="bg-emerald-600 p-4 flex items-center">
          <button 
            onClick={() => window.location.href = '/walkthrough/signup'}
            className="focus:outline-none bg-transparent rounded-full hover:scale-110 transition-transform duration-300"
          >
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10 19l-7-7m0 0l7-7m-7 7h18"
              />
            </svg>
          </button>
          <div className="flex-grow" />
        </div>

        <div className="p-6">
          <h2 className="text-3xl font-bold text-emerald-700 mb-6">Log In</h2>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="username"
                className="block text-lg font-medium text-emerald-700"
              >
                Email
              </label>
              <input
                type="email"
                id="username"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-lg shadow-sm placeholder-gray-400
                          focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 transition-all duration-300"
                value={username}
                required
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-lg font-medium text-emerald-700"
              >
                Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md text-lg shadow-sm placeholder-gray-400
                          focus:outline-none focus:border-emerald-500 focus:ring-1 focus:ring-emerald-500 transition-all duration-300"
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 focus:outline-none bg-transparent hover:bg-transparent"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <svg
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="h-6 w-6 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 5c4.478 0 8.268 2.943 9.542 7a9.956 9.956 0 01-2.83 4.125M12 19c-4.478 0-8.268-2.943-9.542-7a9.956 9.956 0 012.83-4.125"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M2 2l20 20"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            
            <button
              type="submit"
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-lg font-medium text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-all duration-300 transform hover:scale-105"
            >
              Sign In
            </button>
          </form>

          <div className="flex items-center my-6">
  <hr className="flex-grow border-t border-gray-300" />
  <span className="mx-4 text-gray-500 font-medium">OR</span>
  <hr className="flex-grow border-t border-gray-300" />
</div>
<button
  onClick={handleGoogleSignIn}
  className="w-full flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-lg font-medium text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 focus:border-gray-400 mt-4 transition-all duration-300"
  style={{
    border: '1px solid #d1d5db', // Subtle light grey outline
  }}
>
  <img
    src="/google.png"
    alt="Google logo"
    className="h-5 w-5 mr-3 align-middle"
  />
  Continue with Google
</button>


          {/* Error message */}
          <p
            className={`mt-4 text-red-500 font-medium h-5 ${
              error ? 'visible' : 'invisible'
            }`}
          >
            {error}
          </p>

          <div className="mt-6 text-center">
            <p className="text-base text-gray-600">
              Don't have an account?{' '}
              <a href="/walkthrough/signup" className="text-emerald-600 hover:underline">
                Sign up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
