import React, { useState, useEffect } from "react";

function ViewingRequest({ lastAnalyzedProperty }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [message, setMessage] = useState("");

  // Added state for date and time
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (lastAnalyzedProperty && lastAnalyzedProperty.address) {
      setAddress(lastAnalyzedProperty.address);
    }
    // Optionally set default date and time
    const today = new Date().toISOString().split('T')[0];
    setDate(today);
    const currentTime = new Date().toTimeString().split(' ')[0].slice(0,5);
    setTime(currentTime);
  }, [lastAnalyzedProperty]);

  const generateMessage = (e) => {
    e.preventDefault();
    // Convert time to AM/PM format
    const formattedTime = new Date(`1970-01-01T${time}:00`)
      .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    const viewingMessage = `Dear Seller,

I hope this message finds you well. My name is ${name} and I'm interested in viewing the property at ${address} on ${date} at ${formattedTime}. Would it be possible to arrange a viewing?

If this time doesn't work for you, please let me know your availability and I'll do my best to accommodate.

Thank you for your time and consideration. I look forward to hearing from you.

Best regards,
${name}
${email}`;

    setMessage(viewingMessage);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(message).then(() => {
      alert("Message copied to clipboard!");
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h3 className="text-2xl font-bold mb-4">Request a Viewing</h3>
      <p className="text-sm italic text-gray-600 mb-4">
        Disclaimer: This email is a sample and will be updated accordingly.
      </p>
      <form onSubmit={generateMessage}>
        {/* 
          Layout:
          - Non-Small Viewports (sm and up): Name, Date, Time side by side
          - Small Viewports: 
            - Row 1: Name (full width)
            - Row 2: Date and Time side by side
        */}
        <div className="grid grid-cols-2 sm:grid-cols-3 gap-2 mb-2">
          {/* Name Field */}
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="col-span-2 sm:col-span-1 p-2 h-10 border border-gray-300 rounded"
            placeholder="Your Name*"
          />

          {/* Date Field */}
          <input
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            className="col-span-1 p-2 h-10 border border-gray-300 rounded"
            placeholder="Date*"
          />

          {/* Time Field */}
          <input
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className="col-span-1 p-2 h-10 border border-gray-300 rounded"
            placeholder="Time*"
          />
        </div>

        {/* Email Field */}
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-4"
          placeholder="Your Email*"
        />

        {/* Property Address Field */}
        <input
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded mb-4"
          placeholder="Property Address*"
        />

        {/* Generate Viewing Request Button */}
        <button
          type="submit"
          className="w-full bg-emerald-600 text-white p-2 rounded hover:bg-emerald-500 transition duration-300"
        >
          Generate Viewing Request
        </button>
      </form>

      {/* Generated Message Section */}
      {message && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Generated Message:</h3>
          <pre className="bg-gray-100 p-4 rounded whitespace-pre-wrap text-sm">
            {message}
          </pre>
          <button
            onClick={copyToClipboard}
            className="mt-4 w-full bg-teal-600 text-white p-2 rounded hover:bg-teal-500 transition duration-300"
          >
            Copy Message 📋
          </button>
          <p className="mt-2 text-sm text-gray-600">
            Click the button above to copy the message to your clipboard.
          </p>
        </div>
      )}
    </div>
  );
}

export default ViewingRequest;
