import React, { useState, useEffect } from "react";

function MakeOffer({ lastAnalyzedProperty }) {
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [offer, setOffer] = useState("");
  const [address, setAddress] = useState("");
  const [generatedOffer, setGeneratedOffer] = useState("");

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];
    setDate(today);

    if (lastAnalyzedProperty && lastAnalyzedProperty.address) {
      setAddress(lastAnalyzedProperty.address);
    }
  }, [lastAnalyzedProperty]);

  const generateOffer = (e) => {
    e.preventDefault();
    const offerTemplate = `
STANDARD FORM OF REAL ESTATE SALES CONTRACT

This CONTRACT is made on ${date},
BETWEEN the Buyer and the Seller.
Buyer: ${name}
Address: [Buyer's address would be filled in here]
AND the Seller: [Seller's name would be filled in here]
Property Address: ${address}

1. PURCHASE AGREEMENT: The Seller agrees to sell and the Buyer agrees to buy the Property and all fixtures and improvements described in this Contract.

2. PURCHASE PRICE: The purchase price is $${offer}.

3. PROPERTY: The Property to be sold consists of (a) the land and all the buildings, other improvements and fixtures on the land; (b) all of the Seller's rights relating to the land; and (c) all personal property specifically listed on the attached schedule.

... [Additional contract terms would be included here] ...

BUYER: ${name}
Date: ${date}

SELLER: ___________________________
Date: ___________________________
    `;

    setGeneratedOffer(offerTemplate);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(generatedOffer).then(() => {
      alert("Offer copied to clipboard!");
    });
  };

  return (
    <div className="max-w-4xl mx-auto">
      <h3 className="text-2xl font-bold mb-4">Make an Offer</h3>
      <p className="text-sm italic text-gray-600 mb-4">Disclaimer: This offer is a sample and will be updated accordingly.</p>
      <form onSubmit={generateOffer}>
        <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} className="w-full p-2 border border-gray-300 rounded"
          placeholder="Property Address*"
        />
        <div className="flex space-x-4">
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="w-1/2 p-2 border border-gray-300 rounded"
            placeholder="Your Name*"
          />
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)} className="w-1/2 p-2 border border-gray-300 rounded" />
        </div>
        <input type="number" value={offer} onChange={(e) => setOffer(e.target.value)} className="w-full p-2 border border-gray-300 rounded" min="0" step="1"
          placeholder="Offer Amount*"
        />
        <button type="submit" className="w-full bg-emerald-600 text-white p-2 rounded hover:bg-emerald-500 transition duration-300">Generate Offer</button>
      </form>
      {generatedOffer && (
        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-2">Generated Offer:</h3>
          <pre className="bg-gray-100 p-4 rounded whitespace-pre-wrap text-sm">{generatedOffer}</pre>
          <button onClick={copyToClipboard} className="mt-4 w-full bg-teal-600 text-white p-2 rounded hover:bg-teal-500 transition duration-300">Copy Offer 📋</button>
          <p className="mt-2 text-sm text-gray-600">Click the button above to copy the offer to your clipboard.</p>
        </div>
      )}
    </div>
  );
}

export default MakeOffer;