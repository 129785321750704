import React from 'react';
import { formatNumber, LabelValue } from '../../utils/CustomComponents';

const ClosingCostsPopup = ({ onClose, cashToClose, financeAgentFee, onToggleFinanceAgentFee }) => {
  if (!cashToClose) return null;

  return (
    <div 
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50" 
      onClick={onClose}
    >
      <div 
        className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full relative" 
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-xl text-center font-bold mb-6">Total Cash to Close</h2>
        <div className="mb-6">
          {/* Down Payment */}
          <LabelValue label="Down Payment (20%)" value={`$${formatNumber(cashToClose.downPayment)}`} />
          
          {/* Closing Costs */}
          <LabelValue label="Closing Costs (5%)" value={`$${formatNumber(cashToClose.closingCosts)}`} />
          
          {/* Agent Fee with Toggle */}
          <div className="flex items-center justify-between">
            <LabelValue 
              label="Agent Fee (3%)" 
            />
            <div className="flex items-center">
              {/* Display "Financed" or the agent fee based on toggle */}
              <span className="mr-4">
                {financeAgentFee ? 'Financed' : `$${formatNumber(cashToClose.agentFee)}`}
              </span>
              
              {/* Toggle Switch */}
              <label htmlFor="financeAgentFeeToggle" className="relative inline-flex items-center cursor-pointer">
                <input 
                  type="checkbox" 
                  id="financeAgentFeeToggle" 
                  className="sr-only peer" 
                  checked={financeAgentFee}
                  onChange={onToggleFinanceAgentFee}
                />
                {/* Toggle Background */}
                <div className={`w-11 h-6 rounded-full transition-colors duration-200 ${
                  financeAgentFee ? 'bg-gray-200' : 'bg-emerald-600'
                }`}></div>
                {/* Toggle Knob */}
                <span 
                  className={`absolute left-1 top-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 
                  ${financeAgentFee ? 'translate-x-0' : 'translate-x-5'}`}
                ></span>
              </label>
            </div>
          </div>
        </div>
        
        {/* Total Cash to Close */}
        <div className="border-t pt-4 mb-6">
          <LabelValue 
            label="Total Cash to Close" 
            value={`$${formatNumber(cashToClose.total)}`} 
          />
        </div>
        
        {/* Close Button */}
        <button
          onClick={onClose}
          className="w-full bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-700 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ClosingCostsPopup;
